export const strapiGetI18nSortKey = (locale: string = '', value: string) => {
  switch (locale) {
    case 'en-US':
      return `${value}_en`
    case 'es-ES':
      return `${value}_es`
    case 'ca-ES':
      return `${value}_ca`
    default:
      return `${value}_en`
  }
}
